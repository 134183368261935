<template>
  <v-card
    rounded
    flat
    class="pa-0 pa-md-2 stat-banner"
  >
    <v-container
      fluid
      class="d-flex flex-column h-100"
      style="align-content: space-between !important;"
    >
      <v-row>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-img
            :src="$img('/owner-photo.jpg', { width: 192, height: 192, quality: 100, format: 'webp' })"
            :lazy-src="$img('/owner-photo.jpg', { width: 48, quality: 10, format: 'webp' })"
            cover
            width="192"
            height="192"
            aspect-ratio="1/1"
            class="rounded-circle mx-auto"
            alt=""
          />
        </v-col>
        <v-col class="d-flex flex-column justify-space-between h-content pa-0 ma-0">
          <v-card-title class="banner-header">
            Hi there!<br>
            I'm <span style="font-weight: 500;">Danny Lemeshev!</span>
          </v-card-title>
          <v-card-text class="text-regular">
            <div>
              Whether you want to find a home, sell, or invest in real estate,<br>
              <br>
              I'm here to be your trusted partner<br>
              and help you close the deal of your dreams.<br>
              <br>
              Let's find your perfect home!
            </div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="h-content flex-grow-0 pa-0 ma-0 pt-3">
        <v-col
          v-for="{ value, descriptor }, i in displayedStats"
          :key="i"
          class="h-content pa-1 stat-item"
          cols="6"
          :sm="!i ? '12' : '4'"
        >
          <split-data
            vertical
            divided
            divider-color="white"
            gap="2px"
            class="bg-primary-50 rounded pa-2 h-content"
          >
            <template #first>
              <span
                class="stat-value"
                v-text="value"
              />
            </template>
            <template #second>
              <span
                class="stat-descriptor"
                v-text="descriptor"
              />
            </template>
          </split-data>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { useCompanyStats } from '~/stores/companyStats'

const stats = useCompanyStats()
const displayedStats = computed(() => Object.values(stats.stats))
</script>

<style scoped lang="scss">
.stat-item {
  min-width: 155px !important;
}

.stat-value {
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 600;
}

.stat-descriptor {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}

.banner-header {
  font-size: 1.75rem;
  font-weight: 200;
}
</style>
