<template>
    <div>
        <v-container
            fluid
            class="page-block pa-sm-8 py-md-12"
        >
            <v-row
                class="banner-row h-content justify-center justify-md-space-between align-stretch"
                style="gap: 10px;"
            >
                <stat-banner class="v-col v-col-auto card-transparent bg-black" />
                <contact-form class="v-col v-col-auto card-transparent bg-black d-none d-md-block" :formName="'Contact now'"/>
            </v-row>
            <v-row
                id="contact-nav"
                class="d-md-none px-5"
            >
                <v-btn
                    class="mx-auto my-4 mx-8 py-3 h-content contact-button w-100"
                    style="max-width: 420px"
                    color="primary"
                    variant="elevated"
                    text="Contact now"
                    to="#contact"
                />
            </v-row>
        </v-container>
        <v-container
            fluid
            class="bg-white page-block"
        >
            <v-row
                v-for="seed in recommendationSeeds"
                :key="seed.title"
                style="overflow: visible;"
                class="my-16 h-content"
            >
                <suspense>
                    <lazy-recommendation-list
                        class="h-content"
                        style="min-width: min(100%, var(--content-width))"
                        :seed="seed"
                    />
                </suspense>
            </v-row>
        </v-container>
        <v-container
            fluid
            class="page-block pa-sm-8 py-md-12"
        >
            <v-row class="px-5">
                <v-btn
                    class="mx-auto my-4 mx-8 py-3 h-content contact-button w-100"
                    style="max-width: 420px"
                    color="primary"
                    variant="elevated"
                    text="Browse Condos and Homes"
                    to="/buy"
                />
            </v-row>
        </v-container>
        <v-container
            id="contact"
            fluid
            class="page-block d-md-none py-8"
        >
            <v-row
                class="h-content"
                justify="center"
            >
                <contact-form
                    class="card-transparent bg-black w-100"
                    style="min-width: 320px"
                    :formName="'Contact now'"
                />
            </v-row>
        </v-container>
    </div>
</template>

<script
    setup
    lang="ts"
>
useSeoMeta({
    description: 'Your South Florida Real Estate Connection. Search millions of for-sale listings, compare home values and connect with local professionals.',
})

const recommendationSeeds = ref([
    {
        title: 'Featured Listings',
        filters: {
            ListOfficeKey: '2c3fc718e3b9d0f97443dea19a1d7cb3',
        },
        link: '/buy?ListOfficeKey=2c3fc718e3b9d0f97443dea19a1d7cb3',
        descendingOrder: true
    },
    {
        title: 'Miami Luxury Condos',
        filters: {
            'ListPrice': [15000, 169000000],
            'Property.PropertyType': ['Residential'],
            'Property.PropertySubType': ['Condominium'],
        },
        link: '/buy?ListPrice=15000&ListPrice=16900000&Property.PropertyType=Residential&Property.PropertySubType=Condominium',
    },
] as { title: string; filters: { [k: string]: any }; link: string }[])
</script>

<style lang="scss">
.stat-banner {
    flex-basis: 430px;
    min-width: 430px;
    max-width: 760px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;

    @media(max-width: 600px) {
        min-width: 350px;
    }
}

.contact-form {
    min-width: 400px;
    max-width: 440px;
    flex-basis: 400px;
    flex-grow: 1;
    flex-shrink: 1;
}

@media screen and (max-width: 500px) {
    .contact-form .contact-button {
        width: 100%;
    }
}

@media screen and (max-width: 1100px) {
    .contact-form.d-md-block {
        display: none !important;
    }

    .banner-row.justify-center {
        justify-content: center !important;
    }

    #contact-nav.d-md-none,
    #contact.d-md-none {
        display: flex !important;
    }
}

#contact-nav>.v-btn>.v-btn__overlay {
    background-color: transparent;
}
</style>
